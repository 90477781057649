import React, { useCallback, useRef, useState, useEffect } from "react";
import ReactFlow, {
  useNodesState,
  useEdgesState,
  addEdge,
  useReactFlow,
  Edge,
  MarkerType,
} from "reactflow";
import "reactflow/dist/style.css";
import CustomNode from "./customnode";
import { useNavigate, useParams } from "react-router-dom";
import FormDialog from "./FormDialog";
import PublishDialogue from "./PublishDialogue";
import { pulishedJson, extractNodesEdges } from "./publishTemplate";
import { useProductsContext } from "../../Contexts/SidebarContext";
import { NetworkManager } from "../../Components/NetworkManager";
import { CommonUtil } from "../../utils/CommonUtil";
import ToastManager from "../../Components/ToastManager";
import CustomEdge from "./CustomEdge";
import InstaPublishDialogue from "./InstaPublishDialogue";
import PublishMessageDialog from "./PublishMessageDialog";
let id = 1;
const getId = () => `${id++}`;
const reset = () => (id = 1);

let nodeInfo = {
  selectednode: {
    handleId: null,
    nodeId: "",
  },
  isConnected: false,
  fromSource: null,
};
const updateNodeInfo = ({ selectednode, isConnected, fromSource }: any) => {
  nodeInfo.selectednode =
    selectednode === undefined ? nodeInfo.selectednode : selectednode;
  nodeInfo.isConnected =
    isConnected === undefined ? nodeInfo.isConnected : isConnected;
  nodeInfo.fromSource = fromSource;
};
let nodeTypes = {
  custom: CustomNode,
};
let edgeTypes = {
  custom: CustomEdge,
};
const ReactFlowTemplate = ({ setITemplateList }: any) => {
  const reactFlowWrapper = useRef<HTMLDivElement | null>(null);
  const [nodes, setNodes, onNodesChange] = useNodesState([]);
  const [edges, setEdges, onEdgesChange] = useEdgesState([]);
  const [dilogue, setDialogue] = useState(false);
  const [editNode, setEditNode] = useState({});
  const [showQuickReplies, setShowQuickReplies] = useState(false);
  const [edgeSource, setEdgeSource] = useState("");
  const [publishDia, setPublishDia] = useState(false);
  const [instaPublishDia, setInstaPublishDia] = useState(false);
  const [publishMessageDia, setPublishMessageDia] = useState(false);
  const [nodeDiaInfo, setNodeDiaInfo] = useState({});
  const { project } = useReactFlow();
  const reactFlowInstance = useReactFlow();
  const [position, setPosition] = useState({ x: 0, y: 0 });
  const {
    nodesAvai,
    edgesAvai,
    startNode,
    saveTemplates,
    templateInfo,
    pageInfo,
    setPubError,
    templates,
    setSelectedTemplateIDs,
    selectedTemplateIDs,
    subscriber,
  } = useProductsContext();

  const navigate = useNavigate();
  const onDeleteNode = (data: any) => {
    onNodesDelete([data]);
  };
  useEffect(() => {
    id = 1;
    if (nodesAvai?.length > 0) {
      id = 0;
      const tempnodesAvai = nodesAvai.map((node: any) => {
        if (Number(node.id) > id) id = Number(node.id);
        let objNode: any = { ...node };
        objNode.id = String(node.id);
        objNode.data.onDeleteNode = onDeleteNode;
        objNode.data.onEdit = onEdit;
        objNode.data.onAddClick = onAddClick;
        objNode.data.onClickQuickReply = onClickQuickReply;
        return objNode;
      });

      id++;
      setNodes(tempnodesAvai);
    } else setNodes(startNode);
    setEdges(
      edgesAvai.map((ele: any) => {
        return {
          ...ele,
          source: String(ele.source),
          type: `custom`,
          data: { onDeleteEdge },
        };
      })
      // .filter((elem: any) => !elem.id?.includes("reactflow"))
    );
  }, []);

  const onConnect = useCallback(
    (params: any) => {
      updateNodeInfo({ isConnected: true });
      const markerProp = {
        zIndex: 100,
        style: {
          strokeWidth: 1,
          stroke: "#FF0072",
        },
        markerEnd: {
          color: "#FF0072",
          type: MarkerType.ArrowClosed,
        },
      };
      const updatedEdge = {
        ...params,
        ...markerProp,
        type: `custom`,
        data: { onDeleteEdge },
      };
      setEdges((els: any) => {
        const temp = addEdge(updatedEdge, els);
        return temp;
      });
    },
    [setEdges]
  );
  const onSaveOrCancelDialogue = (data: any) => {
    setDialogue(false);
    setShowQuickReplies(false);
    setNodeDiaInfo(data);
    createCustonNode(data);
  };
  const onCancelDialogue = (data: any) => {
    setDialogue(false);
    setPublishDia(false);
    setInstaPublishDia(false);
    setPublishMessageDia(false);
    setShowQuickReplies(false);
    setEditNode({});
  };
  const onSuccessCall = (data: any) => {
    var respData = CommonUtil.parseData(data);
    if (respData?.statusCode && respData?.statusCode === "Template2000") {
      setPubError("");
      setPublishDia(false);
      ToastManager.showToast("Template Saved ", "Success");
      navigate("/dashboard/templates");
    } else if (
      respData?.statusCode &&
      respData?.statusCode === "Template3001"
    ) {
      setPubError(
        `The Entered Template Name "${respData.templateName}" already exists.Please try a new one.`
      );
    }
  };

  const onSuccessInstaCall = (data: any) => {
    var respData = CommonUtil.parseData(data);
    if (respData?.statusCode && respData?.statusCode === "Template2000") {
      setPubError("");
      setInstaPublishDia(false);
      ToastManager.showToast("Template Saved ", "Success");
      navigate("/dashboard/instatemplates");
    } else if (
      respData?.statusCode &&
      respData?.statusCode === "Template3001"
    ) {
      setPubError(
        `The Entered Template Name "${respData.templateName}" already exists.Please try a new one.`
      );
    }
  };
  const onSuccessInstaUpdateCall = (data: any) => {
    var respData = CommonUtil.parseData(data);
    if (
      respData?.statusCode &&
      (respData?.statusCode === "Template2000" ||
        respData?.statusCode === "Insta0000")
    ) {
      setPubError("");
      setInstaPublishDia(false);
      ToastManager.showToast("Template Saved ", "Success");
      navigate("/dashboard/instatemplates");
    } else if (
      respData?.statusCode &&
      respData?.statusCode === "Template3001"
    ) {
      setPubError(
        `The Entered Template Name "${respData.templateName}" already exists.Please try a new one.`
      );
    }
  };
  const onErrorCall = (err: any) => {
    setPubError("");
    ToastManager.showToast("Error Saving the Template ", "Error");
  };
  const onErrorCallSetDefault = (err: any) => {
    setPubError("");
  };
  const onSuccessCallSetDefault = (data: any) => {
    setPubError("");
    setPublishDia(false);
    id = 1;
  };

  const onSaveOptinTemplateName = (
    optinTemplateName: any,
    optinTemplateId: any,
    scheduledTime: string,
    listOfPayloads: any[]
  ) => {
    const outputData = pulishedJson(
      nodes,
      edges,
      optinTemplateName,
      pageInfo.pageId,
      ""
    );
    const payloadData = listOfPayloads.join(", ");

    let params: any = "";
    let path = "saveOptinTemplate";
    const fbPageInfo: any = localStorage.getItem("fbPageDetail");
    if (fbPageInfo) {
      const recFbPageInfp = JSON.parse(fbPageInfo)?.facebook;
      params = `pageId=${recFbPageInfp.fbPageId}&optinTemplateName=${optinTemplateName}&scheduledTime=${scheduledTime}&listOfPayloads=${payloadData}`;
    }
    console.log(params, outputData);
    if (
      templateInfo?.optinTemplateName !== undefined &&
      templateInfo?.optinTemplateName !== ""
    ) {
      const recFbPageInfp = JSON.parse(fbPageInfo)?.facebook;
      params = `pageId=${recFbPageInfp.fbPageId}&optinTemplateId=${templateInfo?.optinTemplateId}&listOfPayloads=${payloadData}&scheduledTime=${scheduledTime}`;
      path = "updateOptinTemplate";
    }

    try {
      NetworkManager.sendJsonRequest(
        path,
        outputData,
        params,
        "POST",
        true,
        onSuccessOptinCall,
        onErrorCall
      );
    } catch (error) {
      console.log(error);
    }
  };
  const onSuccessOptinCall = (data: any) => {
    var respData = CommonUtil.parseData(data);
    console.log(respData);

    if (respData?.statusCode && respData?.statusCode === "Template2000") {
      setPubError("");
      setPublishMessageDia(false);
      ToastManager.showToast("Template Saved Successfully ", "Success");
      navigate("/dashboard/subscribers");
    } else if (respData?.statusCode === "Template1104") {
      setPubError(
        `The Entered Template Name "${respData.optinTemplateName}" already exists.Please try a new one.`
      );
    }
  };

  const onSaveTemplateName = (
    templateName: any,
    isDefault: any,
    templateId: any
  ) => {
    const outputData = pulishedJson(
      nodes,
      edges,
      templateName,
      pageInfo.pageId,
      ""
    );
    console.log(outputData);

    let params: any = "";
    let path = "saveRawTemplate";
    const fbPageInfo: any = localStorage.getItem("fbPageDetail");
    if (fbPageInfo) {
      const recFbPageInfp = JSON.parse(fbPageInfo)?.facebook;
      if (templateInfo.templateType === "paused") {
        params = `fbAccountId=${recFbPageInfp.fbAccountId}&pageId=${
          recFbPageInfp.fbPageId
        }&templateName=${templateName}&templateType=${
          isDefault ? "default" : "paused"
        }&previousDefaultTemplateId=${templateId}`;
      } else {
        params = `fbAccountId=${recFbPageInfp.fbAccountId}&pageId=${
          recFbPageInfp.fbPageId
        }&templateName=${templateName}&templateType=${
          isDefault ? "default" : "specific"
        }&previousDefaultTemplateId=${templateId}`;
      }
    }
    if (
      templateInfo?.templateName !== undefined &&
      templateInfo?.templateName !== ""
    ) {
      params = params + `&templateId=${templateInfo?.templateId}`;
      path = "template/updateTemplate";
    }

    try {
      NetworkManager.sendJsonRequest(
        path,
        outputData,
        params,
        "POST",
        true,
        onSuccessCall,
        onErrorCall
      );
    } catch (error) {}
  };
  const selectedConnection: any = localStorage.getItem("selectedConnection");
  const onSaveInstaTemplateName = (
    templateName: any,
    isDefault: any,
    templateId: any
  ) => {
    const outputData = pulishedJson(
      nodes,
      edges,
      templateName,
      pageInfo.pageId,
      "insta"
    );
    let params: any = "";
    let path = "api/instagram/rawTemplate";
    const fbPageInfo: any = localStorage.getItem("fbPageDetail");
    if (fbPageInfo) {
      const recFbPageInfp = JSON.parse(fbPageInfo)?.instagram;
      params = `instaId=${recFbPageInfp.instaId}&pageId=${
        recFbPageInfp.pageId
      }&templateName=${templateName}&templateType=${
        isDefault ? "default" : "specific"
      }&previousDefaultTemplateId=${templateId}`;
    }
    try {
      NetworkManager.sendJsonRequest(
        path,
        outputData,
        params,
        "POST",
        true,
        onSuccessInstaCall,
        onErrorCall
      );
    } catch (error) {}
  };
  const onUpdateInstaTemplateName = (
    templateName: any,
    isDefault: any,
    templateId: any
  ) => {
    const outputData = pulishedJson(
      nodes,
      edges,
      templateName,
      pageInfo.pageId,
      "insta"
    );
    let params: any = "";
    let path = "api/instagram/updateTemplate";
    const fbPageInfo: any = localStorage.getItem("fbPageDetail");
    if (fbPageInfo) {
      if (
        templateInfo?.templateName !== undefined &&
        templateInfo?.templateName !== ""
      ) {
        const recFbPageInfp = JSON.parse(fbPageInfo)?.instagram;
        if (templateInfo?.templateType === "paused") {
          params = `instaId=${recFbPageInfp.instaId}&pageId=${
            recFbPageInfp.pageId
          }&templateType=${
            isDefault ? "default" : "paused"
          }&previousDefaultTemplateId=${templateId}&templateId=${
            templateInfo?.templateId
          }&templateName=${
            templateName ? "" : templateInfo?.templateName
          }&fbAccountId=${selectedTemplateIDs?.fbAccountId}`;
        } else {
          params = `instaId=${recFbPageInfp.instaId}&pageId=${
            recFbPageInfp.pageId
          }&templateType=${
            isDefault ? "default" : "specific"
          }&previousDefaultTemplateId=${templateId}&templateId=${
            templateInfo?.templateId
          }&templateName=${
            templateName ? "" : templateInfo?.templateName
          }&fbAccountId=${selectedTemplateIDs?.fbAccountId}`;
        }
      }
    }
    try {
      NetworkManager.sendJsonRequest(
        path,
        outputData,
        params,
        "POST",
        true,
        onSuccessInstaUpdateCall,
        onErrorCall
      );
    } catch (error) {}
  };
  const onConnectStart = useCallback((_: any, params: any) => {
    updateNodeInfo({
      selectednode: params.handleType === "source" ? params : null,
      isConnected: false,
    });
  }, []);
  const onAddClick = (data: any) => {
    setPosition({ ...position, x: data.clientX, y: data.clientY - 200 });
    updateNodeInfo({
      isConnected: false,
      fromSource: "fromNext",
    });
    setDialogue(true);
  };
  const onClickQuickReply = (data: any) => {
    setPosition({ ...position, x: data.clientX, y: data.clientY - 200 });
    updateNodeInfo({
      isConnected: false,
      fromSource: "quickReplies",
    });
    setShowQuickReplies(true);
  };
  const deleteButtonLevelEdge = (nodeValue: any, node: any) => {
    const currentEdges = reactFlowInstance.getEdges();
    const currentNodes = reactFlowInstance.getNodes();
    const nodeIds = node.map((node: any) => node.label);
    const relatedEdges = currentEdges.filter((edge: any) =>
      nodeIds.includes(edge.source)
    );

    const edgeIds2 = relatedEdges
      .filter((edge: any) => edge.sourceHandle === nodeValue.id)
      .map((edg: any) => edg.id);

    const eliminateEdge = currentEdges.filter((eliEdge: any) => {
      return edgeIds2.indexOf(eliEdge.id) <= -1;
    });
    setEdges(eliminateEdge);
  };
  const onEdit = (data: any) => {
    const currentEdge = reactFlowInstance.getEdges();
    const getOrCheckNodeSourceIsNext = currentEdge.filter((curEdge: any) => {
      return (
        curEdge.target === data.label && curEdge.sourceHandle === "nextStep"
      );
    });
    if (getOrCheckNodeSourceIsNext?.length > 0) {
      data.edgeSource = "nextStep";
      setEdgeSource("nextStep");
    }
    setEditNode(data);
    setDialogue(true);
  };
  const onUpdateNode = (data: any) => {
    const currentNodes = [...reactFlowInstance.getNodes()];
    const tempArray = currentNodes.map((element) => {
      let updateNodeInfo = element;
      let newMsg: string = String(data.message);
      try {
        newMsg = data.message.spilt("\\n").join(String.fromCharCode(10));
      } catch (e) {}
      let action: any = data.supporterCheck || data.detractorCheck;
      let random: any = data?.randomizer?.length > 0;
      if (updateNodeInfo.data.label === data.label) {
        updateNodeInfo.data = {
          ...updateNodeInfo.data,
          buttonType: data.buttonType,
          // cardName: action ? "" : data.cardName,
          // textMessage: action ? "" : newMsg,
          cardName: random ? "" : data.cardName,
          textMessage: random ? "" : newMsg,
          multipleButton: data.multipleButton,
          websiteURL: data.websiteURL,
          hour: random ? "" : data.hour,
          minutes: random ? "" : data.minutes,
          checked: random ? false : data.checked,
          delayCard: random
            ? ""
            : checkAndUpdateDelayCard({
                hour: data.hour,
                minutes: data.minutes,
                checked: data.checked,
              }),
          edgeSource: data.edgeSource,
          isChecked: random ? false : data.isChecked,
          messengerList: random ? "" : data.messengerlist,
          subscriberListName: random ? "" : data.subscriberName,
          optinTitle: random ? "" : data.subscriptionTitle,
          ctatext: random ? "" : data.ctaMessage,
          // supporterCheck: data.supporterCheck ? true : false,
          // detractorCheck: data.detractorCheck ? true : false,
          randomizer: data.randomizer,
        };
      }
      return updateNodeInfo;
    });
    setEditNode({});
    setDialogue(false);
    setNodeDiaInfo(data);
    setNodes(tempArray);
  };
  const checkAndUpdateDelayCard = (dataInfo: any) => {
    if (dataInfo.checked) {
      let timeInminutes = Math.round(dataInfo.hour * 60 + dataInfo.minutes);
      console.log(timeInminutes);

      return timeInminutes;
      //   return 0;
    } else {
      return 0;
    }
  };
  const onDeleteEdge = (id: any) => {
    const currentEdges = reactFlowInstance.getEdges();
    const edgeIds = currentEdges.map((edge: any) => edge.id);
    console.log(edgeIds, id);
    const eliminateEdge = currentEdges.filter(
      (eliEdge: any) => eliEdge.id !== id
    );
    setEdges(eliminateEdge);
  };
  const createCustonNode = (dataInfo: any) => {
    if (!nodeInfo.isConnected && !!nodeInfo.selectednode) {
      const { top, left } = reactFlowWrapper?.current
        ? reactFlowWrapper?.current?.getBoundingClientRect()
        : { top: 0, left: 0 };
      let idNode = getId();
      let newMsg: string = String(dataInfo.message);
      try {
        newMsg = dataInfo.message.split("\\n").join(String.fromCharCode(10));
      } catch (e) {}
      let random: any = dataInfo?.randomizer?.length > 0;
      const newNode = {
        id: idNode,
        type: `custom`,
        data: {
          label: idNode,
          textMessage: random ? "" : newMsg,
          cardName: random ? "" : dataInfo.cardName,
          buttonType: dataInfo.buttonType,
          websiteURL: dataInfo.websiteURL,
          onAddClick: onAddClick,
          onClickQuickReply: onClickQuickReply,
          onDeleteNode: onDeleteNode,
          onEdit: onEdit,
          multipleButton: dataInfo.multipleButton,
          checked: random ? false : dataInfo.checked,
          delayCard: random ? "" : checkAndUpdateDelayCard(dataInfo),
          edgeSource: edgeSource,
          isChecked: random ? false : dataInfo.isChecked,
          messengerList: random ? "" : dataInfo.messengerlist,
          subscriberListName: random ? "" : dataInfo.subscriberName,
          optinTitle: random ? "" : dataInfo.subscriptionTitle,
          ctatext: random ? "" : dataInfo.ctaMessage,
          // supporterCheck: dataInfo.supporterCheck,
          // detractorCheck: dataInfo.detractorCheck,
          randomizer: dataInfo.randomizer,
        },
        style: {
          background: "#63B3ED",
          color: "white",
          width: 100,
        },
        position: project({
          x: position.x - left + (nodeInfo.fromSource ? 120 : -75),
          y: position.y - top,
        }),
      };
      setNodes((nds: any) => nds.concat(newNode));
      if (!nodeInfo.fromSource) {
        const markerProp = {
          zIndex: 100,
          style: {
            strokeWidth: 1,
            stroke: "#FF0072",
          },
          markerEnd: {
            color: "#FF0072",
            type: MarkerType.ArrowClosed,
          },
        };
        const { nodeId, handleId }: any = nodeInfo?.selectednode;
        const newEdge: any = {
          source: nodeId || "",
          sourceHandle: handleId || "",
          target: newNode.id,
          targetHandle: "",
        };
        const updatedEdge = {
          ...newEdge,
          ...markerProp,
          type: `custom`,
          data: { onDeleteEdge },
        };
        setEdges((els: any) => {
          const temp = addEdge(updatedEdge, els);
          return temp;
        });
      }
    }
    console.log(dataInfo);
  };
  const validateNodesAndEdges2 = () => {
    let isValidNode = true;
    for (let i = 0; i < nodes.length; i++) {
      const node: any = nodes[i];
      const multBtn = node?.data?.multipleButton;
      const postbackBtns = multBtn.filter(
        (btnInfo: any) => btnInfo.buttonType === "postback"
      );
      const postBackEdges = edges.filter(
        (edgeInfo: any) => edgeInfo.source === node.cardId
      );
      if (postbackBtns?.length !== postBackEdges?.length) {
        isValidNode = false;
        break;
      }
    }
    return isValidNode;
  };
  const onPublish = () => {
    setPublishDia(true);
  };
  const onInstaPublish = () => {
    setInstaPublishDia(true);
  };
  const onPublishMessage = () => {
    setPublishMessageDia(true);
  };
  const onConnectEnd = useCallback(
    (event: any) => {
      if (!nodeInfo.isConnected && !!nodeInfo.selectednode) {
        if (nodeInfo.selectednode.handleId !== null) {
          const currentEdge = reactFlowInstance.getEdges();
          const getALreadyOnNextEdge = currentEdge.filter(
            (connEdge: any) =>
              connEdge.sourceHandle === nodeInfo.selectednode.handleId &&
              connEdge.source === nodeInfo.selectednode?.nodeId
          );
          if (getALreadyOnNextEdge?.length > 0) {
            if (nodeInfo.selectednode.handleId === "quickReplies") {
              setShowQuickReplies(false);
            } else {
              setDialogue(false);
            }
          } else if (nodeInfo.selectednode.handleId === "quickReplies") {
            setShowQuickReplies(true);
          } else {
            setEdgeSource(nodeInfo.selectednode.handleId);
            setDialogue(true);
          }
        }

        setPosition({ ...position, x: event.clientX, y: event.clientY - 20 });
      }
    },
    [project]
  );
  const onNodesDelete = (deleted: any) => {
    const currentEdges = reactFlowInstance.getEdges();
    const currentNodes = reactFlowInstance.getNodes();

    const tempArray =
      currentNodes &&
      currentNodes.filter((element: any) => {
        return element.id !== deleted[0].label;
      });
    setNodes(tempArray);
    const nodeIds = deleted.map((node: any) => node.label);

    const relatedEdges = currentEdges.filter(
      (edge: any) =>
        nodeIds.includes(edge.source) || nodeIds.includes(edge.target)
    );
    const edgeIds = relatedEdges.map((edge: any) => edge.id);
    const eliminateEdge = currentEdges.filter(
      (eliEdge: any) => edgeIds.indexOf(eliEdge.id) <= -1
    );
    setEdges(eliminateEdge);
  };

  return (
    <div className="wrapper" ref={reactFlowWrapper}>
      <FormDialog
        show={dilogue}
        onSaveOrCancelDialogue={onSaveOrCancelDialogue}
        onCancelDialogue={onCancelDialogue}
        editNode={editNode}
        onUpdateNode={onUpdateNode}
        sourceEdge={edgeSource}
        deleteButtonLevelEdge={deleteButtonLevelEdge}
      />

      <PublishDialogue
        show={publishDia}
        onCancelDialogue={onCancelDialogue}
        onSaveTemplateName={onSaveTemplateName}
      />
      <InstaPublishDialogue
        show={instaPublishDia}
        onCancelDialogue={onCancelDialogue}
        onSaveInstaTemplateName={onSaveInstaTemplateName}
        onUpdateInstaTemplateName={onUpdateInstaTemplateName}
      />
      <PublishMessageDialog
        show={publishMessageDia}
        onCancelDialogue={onCancelDialogue}
        onSaveOptinTemplateName={onSaveOptinTemplateName}
        setPublishMessageDia={setPublishMessageDia}
      />

      {/* <button>Publish</button> */}
      <ReactFlow
        nodes={nodes}
        edges={edges}
        onNodesChange={onNodesChange}
        onEdgesChange={onEdgesChange}
        onConnect={onConnect}
        onConnectStart={onConnectStart}
        onConnectEnd={onConnectEnd}
        nodeTypes={nodeTypes}
        edgeTypes={edgeTypes}
        minZoom={0.2}
        maxZoom={4}
      >
        {selectedConnection === "nav-fb-tab" ? (
          subscriber === "subscriber" ? (
            <div className="save__controls">
              <button onClick={onPublishMessage} className="publish_btn">
                {templateInfo?.optinTemplateName !== undefined &&
                templateInfo?.optinTemplateName !== ""
                  ? "Update"
                  : "Send"}
              </button>
            </div>
          ) : (
            <div className="save__controls">
              <button onClick={onPublish} className="publish_btn">
                {templateInfo?.templateName !== undefined &&
                templateInfo?.templateName !== ""
                  ? "Update"
                  : "Save"}
              </button>
            </div>
          )
        ) : selectedConnection === "nav-inst-tab" ? (
          subscriber === "subscriber" ? (
            <div className="save__controls">
              <button onClick={onPublishMessage} className="publish_btn">
                {templateInfo?.optinTemplateName !== undefined &&
                templateInfo?.optinTemplateName !== ""
                  ? "Update"
                  : "Send"}
              </button>
            </div>
          ) : (
            <div className="save__controls">
              <button onClick={onInstaPublish} className="publish_btn">
                {templateInfo?.templateName !== undefined &&
                templateInfo?.templateName !== ""
                  ? "Update"
                  : "Save"}
              </button>
            </div>
          )
        ) : selectedConnection === "nav-fbinsta-tab" ? (
          subscriber === "subscriber" ? (
            <div className="save__controls">
              <button onClick={onPublishMessage} className="publish_btn">
                {templateInfo?.optinTemplateName !== undefined &&
                templateInfo?.optinTemplateName !== ""
                  ? "Update"
                  : "Send"}
              </button>
            </div>
          ) : (
            ""
          )
        ) : (
          ""
        )}
      </ReactFlow>
    </div>
  );
};

export default ReactFlowTemplate;
