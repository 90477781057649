import React, { useContext, useState } from "react";
import { startNode } from "./dafaultNodeAndEdge";
const SidebarContext = React.createContext();

export const SidebarContextPrv = ({ children }) => {
  const [sideBar, setSideBar] = useState(true);
  const [edgesAvai, setEdgesAvai] = useState([]);
  const [nodesAvai, setNodesAvai] = useState([]);
  const [templates, setTemplates] = useState([]); //pageIdAndName, setPageIdAndName]
  const [templateInfo, setTemplateInfo] = useState({});
  const [selectedTemplateIDs, setSelectedTemplateIDs] = useState({});
  const [subscriber, setSubscriber] = useState({});
  const [pageInfo, setPageInfo] = useState({
    pageId: null,
    pageName: null,
    fbAccountId: null,
    instaId: null,
  });
  const [pubError, setPubError] = useState("");
  const openSidebar = () => {
    setSideBar((prevSideBar) => !prevSideBar);
  };
  const saveNewNodeAndEdges = (nodeNew, edgeMew) => {
    setNodesAvai(nodeNew);
    setEdgesAvai(edgeMew);
  };

  const saveTemplates = (outTemp) => {
    setTemplates(() => [outTemp]);
  };
  const savePageIdAndName = (pageId, pageName, fbAccountId, instaId) => {
    setPageInfo({ ...pageInfo, pageId, pageName, fbAccountId, instaId });
    console.log(pageInfo);
  };
  const saveTemplateName = (template) => {
    setTemplateInfo({
      templateName: template.templateName,
      templateId: template.templateId,
      templateType: template.templateType,
      optinTemplateName: template.optinTemplateName,
      optinTemplateId: template.optinTemplateId,
      scheduledTime: template.scheduledTime,
      listOfPayloads: template.listOfPayloads,
    });
  };

  return (
    <SidebarContext.Provider
      value={{
        sideBar,
        openSidebar,
        edgesAvai,
        nodesAvai,
        startNode,
        templates,
        saveTemplates,
        saveNewNodeAndEdges,
        savePageIdAndName,
        pageInfo,
        pubError,
        setPubError,
        saveTemplateName,
        templateInfo,
        setSelectedTemplateIDs,
        selectedTemplateIDs,
        subscriber,
        setSubscriber,
      }}
    >
      {children}
    </SidebarContext.Provider>
  );
};
// make sure use
export const useProductsContext = () => {
  return useContext(SidebarContext);
};
