import React from "react";
import { useNavigate } from "react-router-dom";
import { Header } from "../../Components/Header/Header";
import "./styles.css";

interface HomeProps {}

const Home: React.FC<HomeProps> = () => {
  const history = useNavigate();
  //localStorage.clear();
  const handleConnect = () => {
    history("/connect");
  };

  const handleDashboard = () => {
    history("/dashboard/chatbot");
  };
  const handleExistingPage = () => {
    history("/existingpages");
  };

  return (
    <div className="account-pages container-login100">
      <div className="container">
        <Header />

        <div className="row justify-content-center mb-2">
          <div className="col-md-6 col-lg-6 col-xl-4">
            <div
              className="card"
              onClick={() => handleConnect()}
              style={{ cursor: "pointer" }}
            >
              <div className="card-body text-center">
                <div className="card-block">
                  <div className="d-block">
                    <img src="../assets/images/new-page.png" alt="" />
                  </div>
                  <h4 className="card-title mt-3 mb-0">Connect New</h4>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="row justify-content-center mb-2">
          <div className="col-md-6 col-lg-6 col-xl-4">
            <div
              className="card"
              onClick={() => handleExistingPage()}
              style={{ cursor: "pointer" }}
            >
              <div className="card-body text-center">
                <div className="card-block">
                  <div className="d-block">
                    <img src="../assets/images/key.png" alt="" />
                  </div>
                  <h4 className="card-title mt-3 mb-0">
                    Access Existing
                  </h4>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* <div className="row justify-content-center">
          <div className="col-md-6 col-lg-6 col-xl-4">
            <div
              className="card"
              onClick={handleDashboard}
              style={{ cursor: "pointer" }}
            >
              <div className="card-body text-center">
                <div className="card-block">
                  <div className="d-block">
                    <img src="../assets/images/chatbot-tool.png" alt="" />
                  </div>
                  <h4 className="card-title mt-3 mb-0">ChatBot Tool</h4>
                </div>
              </div>
            </div>
          </div>
        </div> */}
      </div>
    </div>
  );
};

export default Home;
