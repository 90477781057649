import React from "react";
import { Routes, Route } from "react-router-dom";
import Home from "../Home/Home";
import Connect from "../Connect/Connect";
import Login from "../Login/Login";
import FacebookManage from "../FacebookManage/FacebookManage";
import ConnectThankyou from "../ConnectThankyou/ConnectThankyou";
import ConnectError from "../ConnectError/ConnectError";
import Register from "../Register/Register";
import Dashboard from "../Dashboard/Dashboard";
import ExistingFBPages from "../FacebookManage/ExistingFBPages";
import ChatBotTool from "../chatbottool";
interface RoutesComponentProps {}

const RoutesComponent: React.FC<RoutesComponentProps> = () => {
  return (
    <Routes>
      <Route path="/" element={<Login />} />
      <Route path="/home" element={<Home />} />
      <Route path="/connect" element={<Connect />} />
      <Route path="/facebookManage" element={<FacebookManage />} />
      <Route path="/connectSuccess" element={<ConnectThankyou />} />
      <Route path="/connectError" element={<ConnectError />} />
      <Route path="/register" element={<Register />} />
      <Route path="/dashboard/:menu" element={<Dashboard />} />
      <Route path="/existingpages" element={<ExistingFBPages />} />

      {/* <Route path="/dashboard/:menu" element={<Dashboard />} /> */}
      <Route path="*" element={<ConnectError />} />
    </Routes>
  );
};

export default RoutesComponent;
