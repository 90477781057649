import React from "react";
import { useNavigate } from "react-router-dom";
import { SideMenuList } from "../../utils/constants";
import { useProductsContext } from "../../Contexts/SidebarContext";
import { useMediaQuery } from "react-responsive";
function SideMenu({ menu, connectiontype }: any) {
  const navigate = useNavigate();
  const { sideBar, setSubscriber } = useProductsContext();
  const isMobile = useMediaQuery({ query: "(max-width: 768px)" });
  const handleMenu = (menuItem: any) => {
    setSubscriber("");
    navigate(`/dashboard/${menuItem}`);
  };

  return (
    <div className="opensidemenu">
      <div
        className="left-side-menu"
        style={{
          width: sideBar ? "220px" : isMobile ? "0px" : "80px",
        }}
      >
        <div id="sidebar-menu">
          <ul className="metismenu" id="side-menu">
            {SideMenuList.map((menuItem: any, index: any) => {
              const selectedConnection: any =
                localStorage.getItem("activeConnection");
              if (menuItem?.connectiontype) {
                // console.log(menuItem?.connectiontype,selectedConnection);
                if (
                  menuItem?.connectiontype?.indexOf(selectedConnection) === -1
                )
                  return null;
              }
              return (
                <li
                  key={index}
                  onClick={() => {
                    handleMenu(menuItem?.menu);
                  }}
                >
                  <p
                    className={`waves-effect waves-light anchor-tag ${
                      menu === menuItem?.menu ? "active" : ""
                    }`}
                  >
                    {isMobile ? "" : <i className={`${menuItem?.icon}`}></i>}
                    <span style={{ display: sideBar ? "block" : "none" }}>
                      {" "}
                      {isMobile ? <i className={`${menuItem?.icon}`}></i> : ""}
                      {menuItem?.label}{" "}
                    </span>
                  </p>
                </li>
              );
            })}
          </ul>
        </div>
      </div>
    </div>
  );
}

export default SideMenu;
