import React, { useEffect, useState } from "react";
import { useProductsContext } from "../../Contexts/SidebarContext";
import ScheduledDataTable from "./ScheduledDataTable";
import HistoryDataTable from "./HistoryDataTable";
import { useNavigate, useParams } from "react-router-dom";
import { SideMenuList } from "../../utils/constants";
import ChatBotTool from "../chatbottool";
import { CommonUtil } from "../../utils/CommonUtil";
import { NetworkManager } from "../../Components/NetworkManager";
import ToastManager from "../../Components/ToastManager";

const SubscribersPage = () => {
  let pageId: string = "";
  let pageName: any = null;
  let fbAccountId: any = null;

  const [optinTemplateList, setOptinTemplateList] = useState([]);

  useEffect(() => {
    const fbPageInfo: any = localStorage.getItem("fbPageDetail");
    const recFbPageInfp = JSON.parse(fbPageInfo)?.facebook;

    pageId = recFbPageInfp?.fbPageId;
    pageName = recFbPageInfp?.pageName;
    fbAccountId = recFbPageInfp?.fbAccountId;
    if (pageId) {
      console.log(pageId);

      let pageDataReq = "pageId=" + pageId;

      try {
        NetworkManager.sendJsonRequest(
          "getOptinTemplatesbyPageId",
          "",
          pageDataReq,
          "GET",
          true,
          onSuccessGetList,
          onErrorCall
        );
      } catch (error) {
        console.log(error);
      }
      savePageIdAndName(pageId, pageName, fbAccountId);
    }
  }, []);

  const onSuccessGetList = (data: any) => {
    var respData = CommonUtil.parseData(data);
    console.log(respData);

    if (respData) {
      if (respData.statusCode === "Template2000") {
        const optinTemplateList = respData.optinTemplateDetailsList;
        setOptinTemplateList(optinTemplateList);
      } else if (respData.statusCode === "Template1105") {
        ToastManager.showToast("No Optin templates for the pageId", "Error");
      }
    }
  };

  const onErrorCall = (err: any) => {
    console.log(err);
  };

  const { sideBar, savePageIdAndName, pageInfo, setSubscriber } =
    useProductsContext();

  const navigate = useNavigate();

  const handleMenu = () => {
    setSubscriber("subscriber");
    navigate(`/dashboard/chatbot`, { state: { create: true, from: "fb" } });
    // navigate("/chatbottool");
  };

  return (
    <>
      <div
        className="content-page"
        style={{
          marginLeft: sideBar ? "230px" : "120px",
          backgroundColor: "#fff",
        }}
      >
        <div className="content">
          <div className="container-fluid">
            <div className="row"></div>
            <div className="sec-wrapper">
              <div className="d-flex align-items-center justify-content-between mb-3">
                <div
                  className="title"
                  style={{ fontWeight: 600, fontSize: "16px" }}
                >
                  Scheduled
                </div>
                <div className="text-end">
                  <button
                    type="button"
                    onClick={handleMenu}
                    className="btn btn-primary rounded-pill new-chat"
                  >
                    New Subscriber Template
                  </button>
                </div>
              </div>
              <div
                className="tablerounededCorner-subscriber table-responsive"
                style={{ height: "180px" }}
              >
                <ScheduledDataTable
                  rows={optinTemplateList}
                  setOptinTemplateList={setOptinTemplateList}
                />
              </div>
              <div style={{ marginTop: "30px" }}>
                <div className="d-flex align-items-center justify-content-between mb-3">
                  <div
                    className="title title-text"
                    style={{ fontWeight: 600, fontSize: "16px" }}
                  >
                    History
                  </div>
                </div>
                <div
                  className="tablerounededCorner-subscriber table-responsive"
                  style={{ height: "175px" }}
                >
                  <HistoryDataTable
                    rows={optinTemplateList}
                    setOptinTemplateList={setOptinTemplateList}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default SubscribersPage;
