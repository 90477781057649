import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { NetworkManager } from "../../Components/NetworkManager";
import { CommonUtil } from "../../utils/CommonUtil";
import { Box, Button, Grid, Typography } from "@mui/material";
import ToastManager from "../../Components/ToastManager";

interface LoginProps {}

const Login: React.FC<LoginProps> = () => {
  const history = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [emailError, setEmailError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [error, setError] = useState("");
  const [mfacode, setMfacode] = useState("");
  const [errorcode, setErrorcode] = useState("");
  const [verifyData, setVerifyData] = useState(false);
  const [loginData, setLoginData] = useState(true);
  const [showPassword, setShowPassword] = useState(false);
  const [session, setSession] = useState("");

  let onVerifySuccess = (data: any) => {
    var restData = CommonUtil.parseData(data);
    if (restData && restData.session) {
      // localStorage.setItem("session", restData.session);
      // history("/home");
      setLoginData(false);
      setVerifyData(true);
      setSession(restData.session);
      setMfacode("");
    } else {
      setError("Incorrect UserName or Password");
    }
  };

  let onLoginError = (err: any) => {};

  const handleSubmit = () => {
    if (email !== "" && password !== "") {
      let postData = {
        email: email,
        password: password,
      };
      NetworkManager.sendJsonRequest(
        "cognito/login",
        postData,
        "",
        "POST",
        true,
        onVerifySuccess,
        onLoginError
      );
    } else if (email === "" && password === "") {
      setEmailError("Please Enter Email Address");
      setPasswordError("Please Enter Password");
    } else if (email === "") {
      setEmailError("Please Enter Email Address");
    } else if (password === "") {
      setPasswordError("Please Enter Password");
    }
  };
  const onHandleLogin = () => {
    if (email !== "" && password !== "") {
      let postData = {
        session: session,
        email: email,
        mfaCode: mfacode,
      };
      NetworkManager.sendJsonRequest(
        "cognito/mfa",
        postData,
        "",
        "POST",
        true,
        onLoginSuccess,
        onLoginError
      );
    }
  };

  const onLoginSuccess = (data: any) => {
    var restData = CommonUtil.parseData(data);
    if (restData && restData.cognitoAccessToken) {
      localStorage.setItem("cognitoAccessToken", restData.cognitoAccessToken);
      history("/home");
    } else if (mfacode === "") {
      setError("MFA Code is Required");
    } else if (restData.statusCode !== "L0000") {
      ToastManager.showToast("Entered MFA Code is Incorrect!", "Error");
      setVerifyData(false);
      setLoginData(true);
      setError("");
    }
  };

  const onHandleBack = () => {
    setVerifyData(false);
    setError("");
    setLoginData(true);
  };

  const onPasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  return (
    <div className="account-pages container-login100">
      <div className="container">
        <div className="d-flex flex-column justify-content-center">
          <div className="row justify-content-center">
            <div className="col-md-6 text-center mb-2">
              <img src="../assets/images/Logo.png" alt="" />
            </div>
          </div>

          <div className="row justify-content-center">
            <div className="col-md-8 col-lg-6 col-xl-5">
              <div className="card">
                <div className="card-body">
                  {/* Login Fields Start */}
                  {loginData && (
                    <div>
                      <div className="form-group">
                        <label htmlFor="exampleFormControlSelect1">
                          Email Address
                        </label>
                        <input
                          className="form-control"
                          value={email}
                          onChange={(e) => {
                            setEmail(e.target.value);
                            setEmailError("");
                            setError("");
                          }}
                          type="text"
                          id="username"
                          placeholder="myemail@address.com"
                        />
                      </div>
                      <Grid item>
                        <Box display="flex" justifyContent="flex-start">
                          <Typography className="error-message">
                            {" "}
                            {emailError}
                          </Typography>
                        </Box>
                      </Grid>

                      <div className="form-group eye">
                        <label htmlFor="exampleFormControlSelect1">
                          Password
                        </label>
                        <input
                          id="password-field"
                          type={showPassword ? "text" : "password"}
                          onChange={(e) => {
                            setPassword(e.target.value);
                            setPasswordError("");
                            setError("");
                          }}
                          value={password}
                          className="form-control pwd"
                          placeholder="Password"
                        />
                        {/* <span toggle="#password-field" className="far fa-eye field-icon toggle-password "></span> */}
                        <span
                          className={`far ${
                            showPassword ? "fa-eye-slash" : "fa-eye"
                          } field-icon toggle-password`}
                          onClick={onPasswordVisibility}
                        ></span>
                        {/* <div className="input-group-append">
                          <span
                            className="input-group-text"
                            onClick={onPasswordVisibility}
                            style={{ cursor: "pointer" }}
                          >
                            <i
                              className={`far ${
                                showPassword ? "fa-eye-slash" : "fa-eye"
                              }`}
                            ></i>
                          </span>
                        </div> */}
                      </div>
                      <Grid item>
                        <Box display="flex" justifyContent="flex-start">
                          <Typography className="error-message">
                            {" "}
                            {passwordError}
                          </Typography>
                        </Box>
                      </Grid>
                    </div>
                  )}
                  {/* Login Fields End */}
                  {/* MFA Code Field Form start */}
                  <div>
                    {verifyData && (
                      <div>
                        <div>
                          <h6>MFA Required</h6>
                        </div>
                        <div className="form-group">
                          <label>MFA Code:</label>
                          <input
                            className="form-control"
                            value={mfacode}
                            onChange={(e: any) => {
                              setMfacode(e.target.value);
                              setErrorcode("");
                            }}
                            type="number"
                            placeholder="Enter Code"
                          />
                        </div>
                        <Grid item>
                          <Box display="flex" justifyContent="flex-start">
                            <Typography className="error-message">
                              {" "}
                              {errorcode}
                            </Typography>
                          </Box>
                        </Grid>
                      </div>
                    )}
                  </div>
                  {/* MFA Code Field Form End */}
                  <div className="form-group">
                    <div className="custom-control custom-checkbox checkbox-success">
                      <input
                        type="checkbox"
                        className="custom-control-input"
                        id="checkbox-signin"
                        defaultChecked
                      />
                      <label
                        className="custom-control-label"
                        htmlFor="checkbox-signin"
                      >
                        Remember me
                      </label>
                    </div>
                  </div>
                  <div className="form-group">
                    <div className="col-sm-12">
                      <p className="text-muted pointer"> Forgot password?</p>
                    </div>
                  </div>
                  <Grid item>
                    <Box display="flex" justifyContent="flex-start" ml={2}>
                      <Typography className="error-message">{error}</Typography>
                    </Box>
                  </Grid>
                  {loginData && (
                    <div className="form-group text-center mt-4">
                      <div className="col-12">
                        <button
                          onClick={() => handleSubmit()}
                          className="btn btn-bordered btn-primary rounded-pill 
                                            btn-block waves-effect waves-light"
                        >
                          Verify
                        </button>
                      </div>
                    </div>
                  )}
                  {verifyData && (
                    <div className="form-group text-center mt-4">
                      <div
                        className="col-12"
                        style={{ display: "flex", justifyContent: "center" }}
                      >
                        <div className="col-2">
                          <button
                            className="back-button"
                            onClick={onHandleBack}
                          >
                            Back
                          </button>
                        </div>
                        <div className="col-10">
                          <button
                            onClick={onHandleLogin}
                            className="btn btn-bordered btn-primary rounded-pill 
                                            btn-block waves-effect waves-light"
                          >
                            Log In
                          </button>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
