interface Template {
  templateId: String;
  templateName: String;
  pageId: String | null;
  postId: String | null;
  firstCardId: number | null;
  card: Card[] | [];
  flows: Flow[] | [];
  position: Position[] | [];
}

interface Card {
  cardId: number | null;
  cardName: String | null;
  textMessage: String | null;
  nextCardId: number | null;
  delayTimeForNextCard: Number | null;
  optinTitle: String | null;
  messengerList: String | null;
  subscriberListName: String | null;
  ctatext: String | null;
  isChecked: Boolean | null;
  // supporterCheck: Boolean | null;
  // detractorCheck: Boolean | null;
  randomizer: any | null;
  randomType: String | null;
}

interface Flow {
  flowId: String | null;
  responseText: String | null;
  cardId: number | null;
  responseTypeId: String | null;
  responseType: String | null;
  responseSubType: String | null;
  responsePayload: String | null;
  responseUrlLink: String | null;
  nextCardId: number | null;
  delayTimeForNextCard: Number | null;
  content_type: String | null;
  subscriberListName: String | null;
  saveResponseAs: {} | null;
}
interface Position {
  cardId: String | null;
  x: String | null;
  y: String | null;
  buttons: [] | null;
}

export const pulishedJson = (
  nodes: any,
  edges: any,
  templateName: any,
  pageId: any,
  pageType: any
) => {
  const getNodeFOrTheEdge = (cardId: any) => {
    const onnExtEdges = nodes?.filter(
      (nodeItem: any) => nodeItem.id === cardId
    );

    if (onnExtEdges && onnExtEdges.length === 1) {
      return onnExtEdges;
    } else {
      return "";
    }
  };
  const getNextCardIdForNodes = (node: any) => {
    const cardId = node.id;
    const onnExtEdges = edges?.filter(
      (edgeItem: any) =>
        edgeItem.source === cardId && edgeItem.sourceHandle === "nextStep"
    );

    if (onnExtEdges?.length > 0) {
      return onnExtEdges[0].target;
    } else {
      return 0;
    }
  };
  const findNodeById = (id: number) => {
    let node: any = {};
    try {
      node = template.card?.filter((ele: any) => ele.cardId == id)[0];
      node.typeOfNode = node.supporterCheck
        ? "Supporter"
        : node.detractorCheck
        ? "Detractor"
        : "";
      node.cardId = id;
      node.nextCardId = node.nextCardId;
    } catch (e) {
      node = {
        cardId: id,
      };
    }
    return node;
  };

  const sourceHandles = ["A1", "B2", "C3", "D4", "E5", "F6"];
  const getNextCardIdForRandomizer1 = (node: any) => {
    const cardId = node.id;
    const nextCardIdRandom: { [key: string]: any } = {};
    sourceHandles.forEach((handle: any) => {
      const nextEdge = edges?.find(
        (edgeItem: any) =>
          edgeItem.source === cardId && edgeItem.sourceHandle === handle
      );
      nextCardIdRandom[handle] = nextEdge ? nextEdge.target : 0;
    });
    return nextCardIdRandom;
  };
  const getNextCardIdForRandomizer = (node: any, sourceHandle: string) => {
    const nextCardIdRandom = getNextCardIdForRandomizer1(node);
    return nextCardIdRandom[sourceHandle];
  };

  const getPrvCardForDelayTime = (node: any, tempCard: any) => {
    if (node?.data?.delayCard > 0) {
      const cardId = node.id;
      // const buttonType = node.buttonType`${index}`;
      const onnExtEdges = edges?.filter(
        (edgeItem: any) => edgeItem.target === cardId
      );
      if (onnExtEdges?.length > 0) {
        tempCard.map((card: any) => {
          onnExtEdges.map((tempEdge: any) => {
            if (card.cardId === tempEdge.source) {
              card.delayTimeForNextCard = node.data.delayCard;
            }
          });
        });
        return 0;
      } else {
        return 0;
      }
    }
    return 0;
  };

  const updateForDelayTime = (cards: any, nodes: any, edges: any) => {
    let newcards: any = [...cards];
    if (edges?.length > 0) {
      edges.map((tempEdge: any) => {
        let delayedNode: any = null;
        try {
          const node = nodes.filter(
            (tempnode: any) => tempnode.id == tempEdge.target
          )[1];
          if (node.data.checked) {
            delayedNode = { ...node };
          }
        } catch (e) {
          delayedNode = null;
        }
        if (delayedNode) {
          newcards = cards?.map((tempCard: any) => {
            let obj = { ...tempCard };
            if (tempEdge.source == obj.cardId) {
              obj.delayTimeForNextCard = delayedNode.id;
            }
            return obj;
          });
        }
      });
    }
    return newcards;
  };

  const getButtonTypeAndName = (edgeNode: any, edge: any, type: any) => {
    if (type === "urll") {
      if (edgeNode && edgeNode?.data) {
        return edgeNode.data.websiteURL;
      }
    } else {
      if (edgeNode && edgeNode?.data?.multipleButton?.length > 0) {
        const subButtonType = edgeNode.data.multipleButton
          .filter((mb: any) => mb.id === edge.sourceHandle)
          .map((node: any, i: any) => {
            if (type === "button") {
              if (node.buttonType === "webUrlButton") {
                return "web_url";
              } else if (node.buttonType === "postback") {
                return "postback";
              } else if (node.buttonType === "") {
                return "";
              }
            } else if (type === "name") {
              return node.multiButton;
            } else if (type === "url") {
              return node.webUrl;
            }
          });
        return subButtonType[0];
      }
    }
  };

  let output: any = { template: {} };
  const getFirstCardId = (edges: any) => {
    return edges.filter((srcEdge: any) => srcEdge.source === "0")[0].target;
    // return 1;
  };
  let template: Template = {
    templateId: "1",
    templateName: templateName,
    pageId: pageId,
    postId: null,
    firstCardId: getFirstCardId(edges),
    card: [],
    flows: [],
    position: [],
  };

  // genrating cards
  nodes.map((node: any, index: any) => {
    let temp: Card[] = template.card;
    let card: Card = {
      cardId: node.id,
      cardName: node.data.cardName,
      textMessage: node.data?.isChecked
        ? node.data?.optinTitle
        : node.data?.textMessage,
      nextCardId: getNextCardIdForNodes(node),
      isChecked: node.data?.optinTitle ? true : false,
      optinTitle: node.data?.optinTitle,
      messengerList: node.data?.messengerList,
      subscriberListName: node.data?.subscriberListName,
      ctatext: node.data?.ctatext,
      // buttons: node.data?.multipleButton?.buttonType.join("|"),
      //position: node.position,
      delayTimeForNextCard: getPrvCardForDelayTime(node, temp),
      // supporterCheck: node.data?.supporterCheck ? true : false,
      // detractorCheck: node.data?.detractorCheck ? true : false,
      randomType: node.data?.randomizer?.length > 0 ? "Randomizer" : "",
      randomizer: node.data?.randomizer?.map((item: any, index: number) => ({
        ...item,
        nextCardId: getNextCardIdForRandomizer(node, sourceHandles[index]),
      })),
    };

    temp.push(card);

    let tempPosition: Position[] = template.position;
    let position: Position = {
      cardId: node.id,
      x: node.position.x,
      y: node.position.y,
      buttons: node.data?.multipleButton,
    };
    tempPosition.push(position);

    if (node?.data?.multipleButton?.length > 0) {
      const multBtn = node?.data?.multipleButton;

      multBtn.map((btn: any, i: any) => {
        if (btn.buttonType === "webUrlButton") {
          const webUrlEdge = edges.filter((edge: any) => {
            return edge.source === node.id && edge.sourceHandle === btn.id;
          });

          if (
            webUrlEdge?.length > 0 &&
            webUrlEdge[0].sourceHandle.indexOf("webUrlButton") !== -1
          ) {
          } else {
            const newEdge: any = {
              id: `reactflow__edge-${node.id}${btn.id}${edges.length + 1 + i}`,
              source: node.id,
              sourceHandle: btn.id || "",
              target: 0,
              targetHandle: "",
            };
            edges.push(newEdge);
          }
        }
      });
    } else if (node?.data?.isChecked) {
      const newEdges: any = {
        flowid: `reactflow__edge-${node.id}${edges.length + 1}`,
        source: node.id,
        sourceHandle: "",
        target: 0,
        targetHandle: "",
        ctaMessage: node?.data?.ctatext,
        subscriberName: node?.data?.subscriberListName,
        checked: node?.data?.isChecked,
      };
      edges.push(newEdges);
    }
  });
  // genrating flow
  edges
    .filter((edge: any) => edge.sourceHandle !== "nextStep")
    // .filter((edge2: any) => !edge2.checked)
    .map((edge: any) => {
      let temp: Flow[] = template.flows;
      const edgeNode = getNodeFOrTheEdge(edge.source);
      const { typeOfNode, nextCardId, cardId } = findNodeById(edge.target);
      let flow: Flow = {
        flowId: edge.checked ? edge.flowid : edge.id,
        responseText: edge.checked
          ? edge.ctaMessage
          : getButtonTypeAndName(edgeNode[0], edge, "name"),
        cardId: edge.source,
        responseTypeId: edge.checked ? "" : edge.sourceHandle,
        responseType: edge.checked
          ? "OPTIN_REQUEST"
          : edge.sourceHandle.includes("QUICK_REPLIES")
          ? "QUICK_REPLIES"
          : "BUTTON",
        responseSubType: edge.checked
          ? ""
          : getButtonTypeAndName(edgeNode[0], edge, "button"),
        responsePayload: edge.checked
          ? `${edge.ctaMessage}_OPTIN_REQUEST_${edge.source}_PAYLOAD+${edge.subscriberName}`
          : `${getButtonTypeAndName(edgeNode[0], edge, "name")}_PAYLOAD_${
              edge.source
            }`,
        responseUrlLink: edge.checked
          ? ""
          : edge.sourceHandle.includes("webUrlButton")
          ? getButtonTypeAndName(edgeNode[0], edge, "url")
          : "",
        nextCardId: edge.target,
        delayTimeForNextCard: 0,
        content_type: edge.checked
          ? ""
          : `${edge.sourceHandle.includes("QUICK_REPLIES") ? "text" : ""}`,
        subscriberListName: edge.checked ? edge.subscriberName : "",
        saveResponseAs: edge.checked
          ? {}
          : nextCardId > 0 && typeOfNode
          ? { typeOfNode, nextCardId, cardId }
          : {},
      };
      if (flow.responsePayload?.indexOf("undefined") == -1) temp.push(flow);
    });

  output.template = template;
  try {
    const fbPageInfo: any = localStorage.getItem("fbPageDetail");
    const recFbPageInfp = JSON.parse(fbPageInfo)?.instagram;
    if (pageType === "insta") {
      delete output.template.postId;
      output.template.mediaId = null;
      output.template.instaId = recFbPageInfp.instaId;
    }
  } catch (e) {}

  return output;
};

export const extractNodesEdges = (templateData: any) => {
  let nodes: any[] = [];
  let edges: any[] = [];
  const { template } = templateData;
  let cards: Card[] = template.card;
  const flows: Flow[] = template.flows;
  const positions: Position[] = template.position;

  const getNodePosition = (node: any, type: any) => {
    const positionNodeObj = positions.filter(
      (posi) => posi.cardId === node.cardId
    );

    if (positionNodeObj?.length > 0) {
      const posiNode = positionNodeObj[0];

      if (type === "position") {
        const positionForTheNode = {
          x: posiNode.x,
          y: posiNode.y,
        };

        return positionForTheNode;
      } else if (type === "button") {
        return posiNode.buttons;
      }
    } else {
      if (type === "position") {
        const positionForStartNode = {
          x: 20,
          y: 250,
        };
        return positionForStartNode;
      }
    }
  };
  const getButtonType = (node: any, type: any, index: any) => {
    const getEdge = flows.filter(
      (srcEdge: any) => srcEdge.cardId === node.cardId
    );

    if (getEdge && getEdge.length > 1) {
      let i = index;
      if (i > getEdge.length) {
        i = getEdge.length;
      }
      if (type === "button") {
        return getEdge[i - 1].responseType || "";
      } else if (type === "url") {
        return getEdge[i - 1].responseUrlLink || "";
      }
    }
  };

  nodes = cards?.map((item: Card, index: any) => {
    if (item?.nextCardId != null && item.nextCardId > 0) {
      const onNextEdge = {
        flowId: (flows.length + 1).toString(),
        responseText: "",
        cardId: item.cardId,
        responseTypeId: "nextStep",
        responseType: "",
        responseSubType: "",
        responsePayload: "",
        responseUrlLink: "",
        nextCardId: item.nextCardId,
        delayTimeForNextCard: 0,
        content_type: "",
        subscriberListName: "",
        saveResponseAs: {},
      };
      flows.push(onNextEdge);
    } else if (item?.randomizer?.length > 0) {
      item?.randomizer?.forEach((randomItem: any) => {
        const onNextEdge1 = {
          flowId: (flows.length + 1).toString(),
          responseText: "",
          cardId: item.cardId,
          responseTypeId: randomItem.id,
          responseType: "",
          responseSubType: "",
          responsePayload: "",
          responseUrlLink: "",
          nextCardId: randomItem?.nextCardId,
          delayTimeForNextCard: 0,
          content_type: "",
          subscriberListName: "",
          saveResponseAs: {},
        };
        if (randomItem?.nextCardId) flows.push(onNextEdge1);
      });
    }
    return {
      id: item.cardId,
      type: `custom`,
      data: {
        label: item.cardId,
        cardName: item.cardName,
        textMessage: item.isChecked ? "" : item.textMessage,
        buttonType: getButtonType(item, "button", index),
        websiteURL: getButtonType(item, "url", index),
        multipleButton: getNodePosition(item, "button"),
        delayCard: item.delayTimeForNextCard,
        checked:
          item.delayTimeForNextCard && Number(item.delayTimeForNextCard) > 0
            ? true
            : false,
        isChecked: item.optinTitle ? true : false,
        messengerList: item.messengerList,
        subscriberListName: item.subscriberListName,
        optinTitle: item.optinTitle,
        ctatext: item.ctatext,
        // supporterCheck: item.supporterCheck ? true : false,
        // detractorCheck: item.detractorCheck ? true : false,
        randomizer: item?.randomizer,
        randomType: item?.randomType,
      },
      style: {
        background: "#63B3ED",
        color: "white",
        width: 100,
      },
      position: getNodePosition(item, "position"),
      width: 100,
      height: 354,
      selected: false,
    };
  });
  let tempFlows: any = [];
  try {
    tempFlows = flows.filter(
      (item: any) => item.responsePayload?.indexOf("undefined") == -1
    );
  } catch (e) {
    tempFlows = [...flows];
  }
  edges = tempFlows
    ?.map((item: Flow) => {
      return {
        id: item.flowId,
        source: item.cardId,
        sourceHandle: item.responseTypeId,
        target: item.nextCardId,
        // targetHandle: '',
        zIndex: 100,
        style: {
          strokeWidth: 1,
          stroke: "#FF0072",
        },
        markerEnd: {
          color: "#FF0072",
          type: "arrowclosed",
        },
      };
    })
    .filter((edge: any) => edge !== null);
  let nextCardDelayValue = 0;
  let nextCardId: any = null;
  let nxtNode: any = [];
  let delayCardData: any = [];
  let nextCardObj: any = null;
  nodes.map((echNode: any) => {
    nextCardDelayValue = echNode.data.delayCard;
    echNode.data.delayCard = 0;
    echNode.data.checked = false;
    if (delayCardData?.length > 0) {
      const delayData = delayCardData.filter(
        (dc: any) => dc.nextCardId === echNode.data.label
      );
      if (delayData?.length) {
        echNode.data.delayCard = delayData[0].delayCard;
        if (delayData[0].delayCard > 0) echNode.data.checked = true;
      }
    }
    const getNextNodeFromEdge = edges.filter((nxtEdge: any) => {
      return (
        nxtEdge.source === echNode.data.label &&
        nxtEdge.sourceHandle === "nextStep"
      );
    });
    if (getNextNodeFromEdge?.length > 0) {
      nxtNode = nodes.filter((nNode: any) => {
        return nNode.data.label === getNextNodeFromEdge[0].target;
      });
    }
    if (nxtNode?.length > 0) {
      nextCardId = nxtNode[0].data.label;
      nextCardObj = {
        nextCardId: nextCardId,
        delayCard: nextCardDelayValue,
      };
      delayCardData.push(nextCardObj);
    }
  });
  console.log(edges, nodes);
  return { nodes, edges };
};
