import React, { useState, useEffect } from "react";
import { Box, Tab } from "@mui/material";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import SpecificPostInfo from "./SpecificPostInfo";

import { useProductsContext } from "../../Contexts/SidebarContext";
const PostConnectDialog = ({ isOpen, onCancelDialogue, templateInfo }: any) => {
  const [value, setValue] = useState("specific");
  const [open, setOpen] = useState(false);

  useEffect(() => {
    setOpen(isOpen);
  }, [isOpen]);
  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };
  const handleClose = (data: any) => {
    //setValue("custom")
    onCancelDialogue(data);
  };
  const { pageInfo } = useProductsContext();

  return (
    <div>
      <Dialog
        open={open}
        fullWidth
        PaperProps={{
          sx: {
            width: "60%",
            height: "90%",
            maxWidth: "1000px",
            //maxHeight: "90px",
          },
        }}
      >
        <DialogTitle
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          Specific Post Information
          <IconButton onClick={() => handleClose("specific")}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent
        // sx={{
        //   width: "100%",
        //   bgcolor: "background.paper",
        //   marginLeft: "180px",
        // }}
        >
          <TabContext value={value}>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <TabList
                onChange={handleChange}
                aria-label="Post Connection"
                textColor="primary"
                indicatorColor="primary"
                centered
              >
                <Tab label="Specific Post Info" value="specific" />
              </TabList>
            </Box>

            <TabPanel value="specific">
              <SpecificPostInfo
                templateInfo={templateInfo}
                handleClose={handleClose}
              />
            </TabPanel>
          </TabContext>
        </DialogContent>
      </Dialog>
    </div>
  );
};
export default PostConnectDialog;
