import React, { useEffect } from "react";
import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import chatIcon from "./../../images/chat-icon.png";
import InstaChatIcon from "./../../images/Insta-icon.png";
import { useProductsContext } from "../../Contexts/SidebarContext";

const SelIcon = () => {
  const { subscriber } = useProductsContext();

  const selectedConnection: any = localStorage.getItem("selectedConnection");

  // useEffect(() => {

  //   return () => {

  //   };
  // }, []);

  return selectedConnection === "nav-fb-tab" ? (
    subscriber === "subscriber" ? (
      <img alt="" src={chatIcon} width={23} height={23} />
    ) : (
      <img alt="" src={chatIcon} width={23} height={23} />
    )
  ) : selectedConnection === "nav-inst-tab" ? (
    subscriber === "subscriber" ? (
      <img alt="" src={chatIcon} width={23} height={23} />
    ) : (
      <InstagramIcon sx={{ width: "32px", height: "32px" }} color="secondary" />
    )
  ) : selectedConnection === "nav-fbinsta-tab" ? (
    subscriber === "subscriber" ? (
      <img alt="" src={chatIcon} width={23} height={23} />
    ) : (
      <img alt="" src={chatIcon} width={23} height={23} />
    )
  ) : (
    <InstagramIcon sx={{ width: "32px", height: "32px" }} color="secondary" />
  );
};

export default SelIcon;
