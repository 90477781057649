import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom'
import { NetworkManager } from '../../Components/NetworkManager';
import { CommonUtil } from '../../utils/CommonUtil';
import defaultImage from "../../images/defaultImage.png";

const InstagramAccess = () => {

  const [pagesCount, setPagesCount] = useState<number>(0);
  const [pagesData, setPagesData] = useState<any[]>();
  const history = useNavigate();

  useEffect(() => {

    try {
      NetworkManager.sendJsonRequest(
        "api/account/accessExistingPages",
        "",
        "",
        "GET",
        true,
        onSuccessCall,
        onErrorCall
      );
    } catch (error) { }

  }, []);

  const onSuccessCall = (data: any) => {
    var respData = CommonUtil.parseData(data);
    let connectedFbList = respData.connectedList
    if (connectedFbList) {
      const connectedInstaList = connectedFbList.filter((insta: any) => insta["instagram"] && Object.keys(insta)?.length === 1);
      // console.log(connectedInstaList);

      setPagesData(connectedInstaList);
      setPagesCount(connectedInstaList && connectedInstaList.length);
    }
  };
  const onErrorCall = (err: any) => { };

  const onConnect = (item: any) => {
    const fbPageInfo: any = localStorage.getItem("fbPageDetail");
    if (fbPageInfo) {
      const recFbPageInfp = JSON.parse(fbPageInfo);
      if (recFbPageInfp) {
        localStorage.setItem("fbPageDetail", "");
      }
    }
    localStorage.setItem("fbPageDetail", JSON.stringify(item));
    history("/dashboard/instatemplates");
    //  history("/dashboard/templates", { state: { pageId, pageName } });
  };

  return (
    <>
      <div className="text-center">
        <h3 className="fbtext">
          {pagesData ?
            <div>
              We found {pagesCount ? pagesCount : 0} Instagram Accounts managed by you.
            </div> :
            <div>
              Fetching Instagram Accounts.
            </div>
          }

        </h3>
      </div>
      {pagesData && pagesCount > 0
        ? pagesData.map((item, index) => {
          return (
            <div className="card" key={index}>
              <div className="card-body">
                <div className="d-flex justify-content-between align-items-center">
                  <div className="widgets_div d-flex align-items-center">
                    <div className="icon_div">
                      <span>
                        {item?.instagram?.instaProfilePic ? (
                          <img
                            src={item?.instagram?.instaProfilePic}
                            className="rounded-circle avatar-sm"
                            alt=""
                          />
                        ) : (
                          <img
                            src={defaultImage}
                            alt=""
                            style={{
                              width: "50px",
                              height: "50px",
                              borderRadius: "50%",
                            }}
                          />
                        )}
                      </span>
                    </div>
                    <div className="av-div">{item?.instagram?.instaName}</div>
                  </div>
                  <div className="text-right">
                    {/* pageStatus */}
                    <button
                      type="button"
                      className="btn rounded-pill btn-small btn-sm btn-primary"
                      style={{ float: "right" }}
                      onClick={() => onConnect(item)}
                    >
                      Access
                    </button>
                  </div>
                  {/* <p>{facebookID}</p>
                    <p>{item.pageId}</p> */}
                </div>
              </div>
            </div>
          );
        })
        : null}
    </>
  );
}

export default InstagramAccess;
