import React from 'react'

function Footer() {
    return (
        <footer className="footer">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-md-12 d-flex">
                        2023&copy;Caucus
                    </div>
                </div>
            </div>
        </footer>
    )
}

export default Footer