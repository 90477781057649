import { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import {
  Box,
  Chip,
  FormHelperText,
  InputLabel,
  Typography,
} from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import React, { memo } from "react";
import { useProductsContext } from "../../Contexts/SidebarContext";
import * as Yup from "yup";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { NetworkManager } from "../../Components/NetworkManager";
import { CommonUtil } from "../../utils/CommonUtil";
import ToastManager from "../../Components/ToastManager";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import Multiselect from "multiselect-react-dropdown";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import tz from "dayjs/plugin/timezone";
import localizedFormat from "dayjs/plugin/localizedFormat";
dayjs.extend(utc);
dayjs.extend(tz);
dayjs.extend(localizedFormat);
interface pubDialogue {
  show: boolean;
  onCancelDialogue: Function;
  onSaveOptinTemplateName: Function;
  setPublishMessageDia: any;
}

const btnStyle = { marginTop: 10 };
function PublishMessageDialog({
  show,
  onCancelDialogue,
  onSaveOptinTemplateName,
  setPublishMessageDia,
}: pubDialogue) {
  let pageId: string = "";
  let pageName: any = null;
  let fbAccountId: any = null;

  const [optinPayLoads, setOptinPayLoads] = useState([]);
  const [open, setOpen] = useState(false);
  const [optinTemplateId, setOptinTemplateId] = useState("");
  const [selectedDate, setSelectedDate] = useState<any>("");
  const [selectedChips, setSelectedChips] = useState<any>([]);
  const [allTopics, setAllTopics] = useState<any[]>([]);

  const { pubError, templateInfo, pageInfo, savePageIdAndName, subscriber } =
    useProductsContext();
  // Get user's timezone
  const userTimezone = dayjs.tz.guess();
  // Convert selectedDate to user's timezone
  const userSelectedDate = selectedDate
    ? dayjs(selectedDate).tz(userTimezone)
    : null;

  const isEdit =
    templateInfo.optinTemplateName !== null &&
    templateInfo.optinTemplateName !== undefined;

  const initialValues = {
    optinTemplateName: isEdit ? templateInfo.optinTemplateName : "",
    // scheduledTime: isEdit ? dayjs(templateInfo.scheduledTime) : "",
    optinPayload: isEdit
      ? templateInfo.listOfPayloads.map((payload: any) => ({
          optinPayload: payload,
        }))
      : [],
  };
  const validationSchema = Yup.object().shape({
    optinTemplateName: Yup.string()
      .min(3, "It's too short")
      .required("Please Enter Template Name"),
    // scheduleTime: Yup.string().required("Please Enter the Scheduled Time"),
  });
  const privateOptions =
    selectedChips?.length == optinPayLoads?.length
      ? [...optinPayLoads]
      : [{ optinPayload: "All" }, ...optinPayLoads];
  useEffect(() => {
    if (isEdit) {
      setSelectedChips(
        templateInfo.listOfPayloads.map((payload: any) => ({
          optinPayload: payload,
        }))
      );
      setSelectedDate(
        templateInfo.scheduledTime
          ? dayjs.utc(templateInfo.scheduledTime).tz(userTimezone)
          : null
      );
    }
  }, [isEdit]);

  const handleSelectAll = (selectedList: any, selectedItem: any) => {
    setSelectedChips(selectedList);
    if (selectedItem.optinPayload === "All") {
      setSelectedChips(optinPayLoads);
    }
  };

  const handleRemoveAll = (selectedList: any, removedItem: any) => {
    if (removedItem.optinPayload === "All") {
      setSelectedChips([]);
    } else {
      const updatedSelectedChips = selectedChips.filter(
        (item: any) => item.optinPayload !== removedItem.optinPayload
      );
      setSelectedChips(updatedSelectedChips);
    }
  };

  useEffect(() => {
    setOpen(show);
  }, [show]);

  useEffect(() => {
    if (subscriber === "subscriber") {
      const fbPageInfo: any = localStorage.getItem("fbPageDetail");
      const recFbPageInfp = JSON.parse(fbPageInfo)?.facebook;

      pageId = recFbPageInfp?.fbPageId;
      pageName = recFbPageInfp?.pageName;
      fbAccountId = recFbPageInfp?.fbAccountId;
      if (pageId) {
        let pageDataReq = "pageId=" + pageId;
        try {
          NetworkManager.sendJsonRequest(
            "getOptinPayloadTopics",
            "",
            pageDataReq,
            "GET",
            true,
            onSuccessGetPayLoads,
            onErrorCall
          );
        } catch (error) {
          console.log(error);
        }
        savePageIdAndName(pageId, pageName, fbAccountId);
      }
    }
  }, []);

  const onSuccessGetPayLoads = (data: any) => {
    var respData = CommonUtil.parseData(data);
    if (respData) {
      if (respData.statusCode === "Template2000") {
        const optinPayLoadList = respData.listOfPayloads;
        setOptinPayLoads(optinPayLoadList);
      }
      //  else if (respData.statusCode === "Template1108") {
      //   ToastManager.showToast(
      //     "No Optin Paylaod topics for the pageId",
      //     "Error"
      //   );
      // }
    }
  };

  const onErrorCall = (err: any) => {};

  const handleClose = () => {
    onCancelDialogue();
  };

  const onHandleSave = (value: any) => {
    if (isEdit) {
      onSaveOptinTemplateName(
        value.optinTemplateName,
        optinTemplateId,
        value.scheduledTime,
        value.listOfPayloads
      );
    } else {
      onSaveOptinTemplateName(
        value.optinTemplateName,
        "",
        value.scheduledTime,
        value.listOfPayloads
      );
    }
    setOptinTemplateId("");
  };

  const onSubmit = (values: any, props: any) => {
    const combinedValues = {
      ...values,
      scheduledTime: userSelectedDate
        ? dayjs(userSelectedDate).utc().format("YYYY-MM-DD HH:mm:ss")
        : null,
      listOfPayloads: selectedChips?.map(
        ({ optinPayload }: any) => optinPayload
      ),
    };
    console.log(combinedValues);

    onHandleSave(combinedValues);
    props.resetForm();
  };

  return (
    <div>
      <Dialog
        open={open}
        PaperProps={{
          sx: {
            width: "80%",
            maxHeight: 600,
          },
        }}
      >
        <DialogTitle>Message Information</DialogTitle>
        <DialogContent>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={onSubmit}
          >
            {(props) => (
              <Form>
                <Box>
                  <div
                    className="col-lg-12 d-flex"
                    style={{ paddingRight: "4px", paddingLeft: "0px" }}
                  >
                    <div
                      className="col-lg-6"
                      style={{ paddingRight: "4px", paddingLeft: "0px" }}
                    >
                      <Field
                        as={TextField}
                        name="optinTemplateName"
                        label="Message Title"
                        // size="small"
                        fullWidth
                        error={
                          props.errors.optinTemplateName &&
                          props.touched.optinTemplateName
                        }
                        disabled={
                          templateInfo.optinTemplateName !== null &&
                          templateInfo.optinTemplateName !== undefined
                            ? true
                            : false
                        }
                        helpertext={<ErrorMessage name="optinTemplateName" />}
                        required
                        value={props.values.optinTemplateName}
                        sx={{
                          marginTop: "1.1vh",
                          "& .MuiOutlinedInput-root": {
                            "& fieldset": {
                              maxHeight: "52px",
                            },
                          },
                        }}
                      />
                    </div>
                    <div className="col-lg-6" style={{ paddingLeft: "4px" }}>
                      <div style={{ width: "270px" }}>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DemoContainer components={["DateTimePicker"]}>
                            <DateTimePicker
                              label={
                                userTimezone === "Asia/Calcutta"
                                  ? "Date and Time (IST)"
                                  : userTimezone === "America/New_York"
                                  ? "Date and Time (EST)"
                                  : ""
                              }
                              name="scheduledTime"
                              defaultValue={dayjs("")}
                              disablePast
                              value={userSelectedDate}
                              onChange={(newValue: any) => {
                                const newDate = newValue ? newValue : null;
                                if (newDate !== selectedDate) {
                                  setSelectedDate(newDate);
                                }
                              }}
                              sx={{
                                "& .MuiOutlinedInput-root": {
                                  "& fieldset": {
                                    maxHeight: "52px",
                                  },
                                },
                              }}
                            />
                          </DemoContainer>
                        </LocalizationProvider>
                      </div>
                    </div>
                  </div>
                  <Box sx={{ mt: 3, mb: 8 }}>
                    <Typography sx={{ fontWeight: 600 }}>
                      List of Topics:
                    </Typography>
                    <Box>
                      <Box sx={{ p: "6px 0" }}>
                        <Multiselect
                          options={privateOptions}
                          selectedValues={selectedChips}
                          displayValue="optinPayload"
                          placeholder="Select Topic"
                          closeOnSelect
                          avoidHighlightFirstOption={true}
                          showArrow={true}
                          onSelect={handleSelectAll}
                          onRemove={handleRemoveAll}
                          style={{
                            chips: {
                              background: "#4aa5dc",
                            },
                          }}
                        />
                      </Box>
                      {/* <Box sx={{ mt: 1 }}>
                        <Chip
                          sx={{ height: "30px" }}
                          label={allTopics.join(", ")}
                        />
                      </Box> */}
                    </Box>
                  </Box>
                  <p>{pubError}</p>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    alignContent: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <Box>
                    <Button
                      type="submit"
                      style={btnStyle}
                      sx={{ textTransform: "none", fontSize: "16px" }}
                    >
                      {isEdit ? "Update Message" : "Schedule Message"}
                    </Button>
                    <Button
                      style={btnStyle}
                      sx={{ textTransform: "none", fontSize: "16px", ml: 2 }}
                      onClick={handleClose}
                    >
                      Cancel
                    </Button>
                  </Box>
                </Box>
              </Form>
            )}
          </Formik>
        </DialogContent>
      </Dialog>
    </div>
  );
}
export default memo(PublishMessageDialog);

//onClick={onSubmit}
