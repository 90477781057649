import React from 'react';
interface HeaderProps {
}
export const Header: React.FC<HeaderProps> = () => {
    return (
        <div className="row justify-content-center">
            <div className="col-md-6 text-center mb-4">
                <img src="../assets/images/Logo.png" alt='' />
            </div>
        </div>
    )
}