import React from 'react'
import { useNavigate } from 'react-router-dom'

function Register() {
    const navigate = useNavigate();
    const goToLogin = () => {
        navigate('/')
    }
    return (
        <div className="account-pages mt-5 mb-5">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-md-8 col-lg-6 col-xl-5">
                        <div className="card">

                            <div className="text-center account-logo-box">
                                <div className="mt-2 mb-2">
                                    <span><img src="assets/images/logo.png" alt="" height="36" /></span>
                                </div>
                            </div>

                            <div className="card-body">

                                <div className="form-group">
                                    <input className="form-control" type="email" id="email" placeholder="Email" />
                                </div>

                                <div className="form-group">
                                    <input className="form-control" type="text" id="username" placeholder="Username" />
                                </div>

                                <div className="form-group">
                                    <input className="form-control" type="password" id="password" placeholder="Password" />
                                </div>

                                <div className="form-group">
                                    <div className="checkbox checkbox-success pt-1 pl-1">
                                        <input id="checkbox-signup" type="checkbox" checked />
                                        <label htmlFor="checkbox-signup" className="mb-0">I accept <p className='pointer'>Terms and Conditions</p></label>
                                    </div>
                                </div>

                                <div className="form-group account-btn text-center mt-2">
                                    <div className="col-12">
                                        <button className="btn width-md btn-bordered btn-danger waves-effect waves-light" type="submit">Log In</button>
                                    </div>
                                </div>

                            </div>
                        </div>

                        <div className="row mt-5">
                            <div className="col-sm-12 text-center">
                                <p className="text-muted">Already have account?
                                    <p onClick={goToLogin} style={{ cursor: "pointer" }} className="text-primary ml-1">
                                        <b>Sign In</b>
                                    </p>
                                </p>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    )
}

export default Register